import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { httpRetry } from 'src/common/rxjs-utils';
import { AppConfigService } from 'src/config/app-config.service';
import { NetworkResponse } from 'src/models/network-response';
import { ApiBase } from './api-base';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkApiService extends ApiBase {
  private _log: Logger;

  constructor(private _http: HttpClient, appConfigService: AppConfigService) {
    super(`${appConfigService.serverUrl}v1`);
    this._log = new Logger(this.constructor.name);
  }

  /**
   * Get the enabled networks
   */
  enabledNetworks(): Observable<HttpResponse<NetworkResponse[]>> {
    this._log.debug('Get the current device');

    return this._http
      .get<NetworkResponse[]>(this.buildEndpoint(''), {
        observe: 'response',
      })
      .pipe(
        take(1),
        httpRetry(),
        catchError((httpResponse: HttpErrorResponse) =>
          this.getErrorMessage$(httpResponse)
        )
      );
  }
}

import { Injectable } from '@angular/core';
import { HubConnectionBuilderService } from './hub-connection-builder.service';
import { NetworkStreamingHubService } from './network-streaming-hub.service';
import {StatusStreamingHubService} from "./status-streaming-hub.service";

@Injectable({
  providedIn: 'root',
})
export class StreamingHubServiceBuilder {
  constructor(private _hubConnectionBuilder: HubConnectionBuilderService) {}

  public getBlocks(): NetworkStreamingHubService {
    return new NetworkStreamingHubService(this._hubConnectionBuilder);
  }

  public getStatus(): StatusStreamingHubService {
    return new StatusStreamingHubService(this._hubConnectionBuilder);
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NetworkStatusComponent} from 'src/app/network-status/network-status.component';
import {NetworkDetails} from 'src/models/network-details';
import {Tab} from '../models/tab.model';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  public tabs: Tab[] = [];

  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);

  public BuildTabs(networkDetails: NetworkDetails[]): void {
    networkDetails.forEach((element) => {
      this.tabs.push(new Tab(NetworkStatusComponent, element.name, element));
    });
  }

  public removeTab(index: number) {
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0) {
      this.tabs[this.tabs.length - 1].active = true;
    }
    this.tabSub.next(this.tabs);
  }

  public addTab(tab: Tab) {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active === true) {
        this.tabs[i].active = false;
      }
    }
    tab.id = this.tabs.length + 1;
    tab.active = true;
    this.tabs.push(tab);
    this.tabSub.next(this.tabs);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longDate',
})
export class LongDatePipe implements PipeTransform {
  private static timeFormat(date: Date): string {
    return `${LongDatePipe.prefix(date.getFullYear())}-${LongDatePipe.prefix(date.getMonth() + 1)}-${LongDatePipe.prefix(date.getDate())} ${LongDatePipe.prefix(date.getHours())}:${LongDatePipe.prefix(date.getMinutes())}:${LongDatePipe.prefix(date.getSeconds())}`;
  }

  private static prefix(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  constructor() {}

  // tslint:disable-next-line: no-any
  public transform(value: Date | string | undefined): string | null {
    if (value) {
      return LongDatePipe.timeFormat(new Date(value));
    }
    return null;
  }
}

import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ContentContainerDirective } from 'src/directives/content-container.directive';
import { Tab } from 'src/models/tab.model';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
})
export class TabContentComponent implements OnInit {
  @Input() tab!: Tab;
  @ViewChild(ContentContainerDirective, { static: true })
  contentContainer!: ContentContainerDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.tab.component
    );
    const viewContainerRef = this.contentContainer.viewContainerRef;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.data = this.tab.tabData;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber',
})
export class BigNumberPipe implements PipeTransform {
  constructor() {}

  // tslint:disable-next-line: no-any
  public transform(value: string): BigInt | null {
    if (value) {
      return BigInt(value);
    }
    return null;
  }
}

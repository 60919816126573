import { environment } from 'src/environments/environment';

/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

/**
 * Log output handler function.
 */
export type LogOutput = (
  source: string,
  level: LogLevel,
  // tslint:disable-next-line:no-any
  ...objects: any[]
) => void;

export class Logger {
  public static hasSetLogLevelForEnvironment = false;
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  public static level = LogLevel.Debug;

  /**
   * Additional log outputs.
   */
  public static outputs: LogOutput[] = [];

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  public static enableProductionMode(): void {
    Logger.level = LogLevel.Warning;
  }

  constructor(public source?: string, classInstance?: object) {
    if (environment.production && !Logger.hasSetLogLevelForEnvironment) {
      Logger.enableProductionMode();
      Logger.hasSetLogLevelForEnvironment = true;
    }

    if (classInstance) {
      this.instances(this, classInstance);
    }
  }

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  // tslint:disable-next-line:no-any
  public debug(...objects: any[]): void {
    this.log(console.log, LogLevel.Debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  // tslint:disable-next-line:no-any
  public info(...objects: any[]): void {
    // tslint:disable-next-line: no-console
    this.log(console.info, LogLevel.Info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  // tslint:disable-next-line:no-any
  public warn(...objects: any[]): void {
    this.log(console.warn, LogLevel.Warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  // tslint:disable-next-line:no-any
  public error(...objects: any[]): void {
    this.log(console.error, LogLevel.Error, objects);
  }

  /**
   * Log out the class instances which are injected into the class
   * @param loggerInstance The logger instance
   * @param classInstance The class instance
   */
  // tslint:disable-next-line:no-any
  private instances(loggerInstance: Logger, classInstance: any): void {
    loggerInstance.debug('New instance of this service has been created');
    let services = '';
    for (const i in classInstance) {
      if (
        typeof classInstance[i] === 'object' &&
        classInstance[i] &&
        classInstance[i].constructor
      ) {
        if (services.length === 0) {
          services += classInstance[i].constructor.name;
        } else {
          services += ', ' + classInstance[i].constructor.name;
        }
      }
    }

    if (services.length > 0) {
      loggerInstance.debug(`Injected ${services}`);
    }
  }

  /**
   * Log the output
   * @param func The function
   * @param level The log level
   * @param objects The objects to log
   */
  // tslint:disable-next-line:no-any
  private log(func: Function, level: LogLevel, objects: any[]): void {
    try {
      if (level <= Logger.level) {
        const log = this.source
          ? ['[' + this.source + ']'].concat(objects)
          : objects;
        func.apply(console, log);
        Logger.outputs.forEach((output) =>
          // @ts-ignore
          output.apply(output, [this.source, level].concat(objects))
        );
      }
    } catch (error) {
      // MUTE ERROR
    }
  }
}

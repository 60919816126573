import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate',
})
export class ShortDatePipe implements PipeTransform {
  private static timeFormat(date: Date): string {
    return `${ShortDatePipe.prefix(date.getMinutes())}:${ShortDatePipe.prefix(date.getSeconds())}`;
  }

  private static prefix(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  constructor() {}

  // tslint:disable-next-line: no-any
  public transform(value: Date | undefined): string | null {
    if (value) {
      return ShortDatePipe.timeFormat(new Date(value));
    }
    return null;
  }


}

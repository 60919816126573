import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigModule } from 'src/config/config.module';
import { BigNumberPipe } from 'src/pipes/big-number.pipe';
import { LongDatePipe } from 'src/pipes/longDate.pipe';
import {ShortDatePipe} from '../pipes/shortDate.pipe';
import { ContentContainerDirective } from '../directives/content-container.directive';
import { TabService } from '../services/tab.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NetworkBlockComponent } from './network-block/network-block.component';
import { NetworkStatusComponent } from './network-status/network-status.component';
import { TabContentComponent } from './tab-content/tab-content.component';
import { FooterComponent } from './footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    NetworkStatusComponent,
    NetworkBlockComponent,
    TabContentComponent,
    ContentContainerDirective,
    BigNumberPipe,
    LongDatePipe,
    ShortDatePipe,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ConfigModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCardModule,
    FormsModule,
  ],
  providers: [TabService],
  bootstrap: [AppComponent],
})
export class AppModule {}

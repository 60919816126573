import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { httpRetry } from 'src/common/rxjs-utils';
import { AppConfigService } from 'src/config/app-config.service';
import { NetworkStatusResponse } from 'src/models/network-status/network-status-response';
import { ApiBase } from './api-base';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusApiService extends ApiBase {
  private _log: Logger;

  constructor(private _http: HttpClient, appConfigService: AppConfigService) {
    super(`${appConfigService.serverUrl}v1/`);
    this._log = new Logger(this.constructor.name);
  }

  /**
   * Get the enabled networks
   */
  networkStatusResponse(
    network: string
  ): Observable<HttpResponse<NetworkStatusResponse>> {
    return this._http
      .get<NetworkStatusResponse>(this.buildEndpoint(`${network}/status`), {
        observe: 'response',
      })
      .pipe(
        take(1),
        httpRetry(),
        catchError((httpResponse: HttpErrorResponse) =>
          this.getErrorMessage$(httpResponse)
        )
      );
  }
}

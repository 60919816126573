import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { NetworkDetails } from 'src/models/network-details';
import { NetworkApiService } from 'src/services/network-api.service';
import { Tab } from '../models/tab.model';
import { TabService } from '../services/tab.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  public network: string = 'MAINNET';

  selectedTab!: number;

  dynamicTabs = new Array<Tab>();

  public networkDetails: NetworkDetails[] = [];

  @ViewChild('tabs', { static: false }) tabGroup!: MatTabGroup;

  public selectedTabIndex!: number;

  constructor(
    private _networkApiService: NetworkApiService,
    private tabService: TabService
  ) {}

  ngAfterContentInit(): void {}

  async ngOnInit(): Promise<void> {
    this.tabService.tabSub.subscribe((tabs) => {
      this.dynamicTabs = tabs;
      this.selectedTab = tabs.findIndex((tab) => tab.active);
    });

    this._networkApiService.enabledNetworks().subscribe((networks) => {
      const enabledNetworks = networks.body?.map((network) => {
        return { name: network.name };
      });
      this.networkDetails.push(...enabledNetworks!);

      this.tabService.BuildTabs(this.networkDetails);
    });
  }
}

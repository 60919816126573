import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, of, Subscription } from 'rxjs';
import { catchError, mergeMap, startWith } from 'rxjs/operators';
import { NetworkDetails } from 'src/models/network-details';
import { Endpoint } from 'src/models/network-status/network-endpoint';
import { BlockHandlerService } from 'src/services/block-handler.service';
import { NetworkStatusApiService } from 'src/services/network-status.api.service';
import { StreamingHubServiceBuilder } from 'src/signalr/streaming-hub-builder.service';
import { NetworkStreamingHubService } from 'src/signalr/network-streaming-hub.service';
import {NetworkBlock} from '../../models/block';
import {StatusStreamingHubService} from '../../signalr/status-streaming-hub.service';

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.scss'],
})
export class NetworkStatusComponent implements OnInit, OnDestroy {
  @Input()
  public data: NetworkDetails = {
    name: 'MAINNET'!,
  };
  private networkStatusSubscription: Subscription | undefined;
  private _networkStreamingHubService: NetworkStreamingHubService;
  private _statusStreamingHubService: StatusStreamingHubService;

  public endpoints: Endpoint[] | undefined;

  public networkSubscribed: boolean = false;
  public statusSubscribed: boolean = false;

  constructor(
    private _networkStatusApiService: NetworkStatusApiService,
    private _blockHandlerService: BlockHandlerService,
    streamingHubServiceBuilder: StreamingHubServiceBuilder
  ) {
    this._networkStreamingHubService = streamingHubServiceBuilder.getBlocks();
    this._statusStreamingHubService = streamingHubServiceBuilder.getStatus();
  }

  ngOnDestroy(): void {
    this.networkStatusSubscription?.unsubscribe();
  }

  ngOnInit(): void {

    this.subscribeStatus(this.data.name);
    this.subscribeBlocks(this.data.name);
  }

  public async subscribeBlocks(networkName: string): Promise<void> {
    const networkDetails = {
      name: networkName,
    };

    this._networkStreamingHubService.start(networkDetails, (item) => {

      const networkBlock: NetworkBlock = {
        network: networkDetails.name,
        number: item.number,
        hash: item.hash,
        timestamp: item.timestamp,
        logsBloom: item.logsBloom,
        parentHash: item.parentHash
      };


      this._blockHandlerService.newBlock.next(networkBlock);
    });

    this.networkSubscribed = true;
  }

  public async unsubscribeBlocks(): Promise<void> {
    this._networkStreamingHubService.stop();
    this.networkSubscribed = false;
  }

  public async subscribeStatus(networkName: string): Promise<void> {
    const networkDetails = {
      name: networkName,
    };

    this._statusStreamingHubService.start(networkDetails, (item) => {

      // console.log(`${networkDetails.name} Status: ${JSON.stringify(item)}`);

      this.endpoints = item.endpoints;
    }).catch((error) => {
        this.networkStatusSubscription = interval(10000)
          .pipe(
            startWith(0),
            mergeMap(() => {
              return this._networkStatusApiService
                .networkStatusResponse(this.data.name)
                .pipe(
                  /// swallow exception and try in next timer interval
                  catchError(() => of(undefined))
                );
            })
          )
          .subscribe((result) => {
            if (result && result.body) {
              this.endpoints = result.body.endpoints;
            }
          });
      }
    );

    this.statusSubscribed = true;
  }

  public getStatusClass(status: string | undefined): string {
    switch (status) {
      case 'UNWELL':
        return 'casino_unwell';
      case 'HAPPY':
        return 'casino_healthy';
      default:
        return 'casino_dead';
    }
  }

  public getSyncingClass(syncing: boolean): string {
    return syncing ? 'catching-up' : 'up-to-date';
  }
}

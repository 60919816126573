<div class="network-status-container">

  <div class="item" *ngFor="let endpoint of endpoints">

    <div class="row">
      <div class="label">Client</div>
      <div class="value">{{ endpoint.url }}</div>
      <div class="status"><span [ngClass]="getSyncingClass(endpoint.synchronisation.syncing)"></span></div>
    </div>


    <div class="row">
      <div class="label">Version</div>
      <div class="value">
            <span *ngIf="endpoint.clientName !== undefined; else noClient">
              {{ endpoint.clientName }}
            </span>
        <ng-template #noClient>Not Connected</ng-template>
      </div>
      <div class="status"></div>
    </div>


    <div class="row">
      <div class="label">Chain</div>
      <div class="value">
        Network Id: {{ endpoint.networkId }} Chain Id: {{ endpoint.chainId }}
      </div>
      <div class="status"></div>
    </div>


    <div class="row">
      <div class="label">Peers</div>
      <div class="value">
        <ng-container *ngIf="endpoint.peers.count !== undefined; else noPeers">
          {{ endpoint.peers.count }} at {{ endpoint.peers.whenRetrieved | longDate }}
        </ng-container>
        <ng-template #noPeers> n/a</ng-template>
      </div>
      <div class="status"><span [ngClass]="getStatusClass(endpoint.peers.status)"></span></div>
    </div>

    <div class="row">
      <div class="label">Synchronisation</div>
      <div class="value">
        <div>
        {{
          endpoint.synchronisation.whenSynchronised | longDate
        }}
        </div>
        <div>
          <ng-container *ngIf="endpoint.synchronisation.blockNumber !== undefined; else noBlockNumber">
            {{ endpoint.synchronisation.blockNumber | bigNumber }} at
            {{ endpoint.synchronisation.blockTimeStamp | longDate }}
          </ng-container>
          <ng-template #noBlockNumber>n/a</ng-template>
        </div>
        <div>
          <ng-container *ngIf="endpoint.synchronisation.blockHash !== undefined; else noBlockHash">
            {{ endpoint.synchronisation.blockHash }}
          </ng-container>
          <ng-template #noBlockHash>Waiting for block</ng-template>
        </div>
      </div>
      <div class="status"><span [ngClass]="getStatusClass(endpoint.synchronisation.status)"></span></div>
    </div>
    <div class="row">
      <div class="label">Network activity</div>
      <div class="value">
        <ng-container *ngIf="endpoint.network.lastActivity !== undefined; else noActivity">
          {{ endpoint.network.lastActivity | longDate }}
        </ng-container>
        <ng-template #noActivity>n/a</ng-template>
      </div>
      <div class="status"><span [ngClass]="getStatusClass(endpoint.network.status)"></span></div>
    </div>


    <div class="row">
      <div class="label">Overall</div>
      <div class="value">
        <ng-container *ngIf="endpoint.network.lastTested !== undefined; else noOverall">
          Tested: {{ endpoint.network.lastTested | longDate }}
        </ng-container>
        <ng-template #noOverall>n/a</ng-template>
      </div>
      <div class="status"><span [ngClass]="getStatusClass(endpoint.overall)"></span></div>
    </div>
  </div>
</div>

<button *ngIf="!networkSubscribed" type="button" (click)="subscribeBlocks(data.name)">
  Subscribe
</button>
<button *ngIf="networkSubscribed" type="button" (click)="unsubscribeBlocks()">
  Unsubscribe
</button>
<app-network-block *ngIf="networkSubscribed" [network]="data"></app-network-block>

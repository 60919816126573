import { Logger } from 'src/services/logger.service';

export class Errors {
  /**
   * Runtime error which logs the error out
   * @param error The error message
   * @param logger The logger instance
   * @param objects The objects to log for further details
   */
  public static runtimeErrorWithLogger(
    logger: Logger,
    error: string,
    // tslint:disable-next-line:no-any
    ...objects: any[]
  ): Error {
    logger.error(error, objects);

    // throw same error to stop everything executing
    return new Error(error);
  }

  /**
   * Runtime error
   * @param error The error
   */
  public static runtimeError(error: string): Error {
    return new Error(error);
  }
}

<svg
  id="funfairlogo"
  width="10em"
  height="2.5em"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 643.91 128"
>
  <title>FunFair</title>
  <path
    d="M352.4,83.8A4.79,4.79,0,0,1,357,80h65.1a3.4,3.4,0,0,0,3.1-2.6l2.5-12.8a2,2,0,0,0-1.4-2.5,1.7,1.7,0,0,0-.7-.1h-69c-9.9,0-19.5,8-21.4,17.9l-.8,3.8-2,10.2-.8,3.8-2.8,14.1-.8,3.8-1.3,6.4-.8,3.8-.3,1.5-1.2,6.1-1.5,7.7a2,2,0,0,0,1.4,2.5,1.7,1.7,0,0,0,.7.1h12.8a3.4,3.4,0,0,0,3.1-2.6l1.5-7.7,1.5-7.6.8-3.8,1.3-6.4h69a3.4,3.4,0,0,0,3.1-2.6l2.5-12.8a2,2,0,0,0-1.4-2.5,1.7,1.7,0,0,0-.7-.1h-69l.8-3.8Z"
    transform="translate(-16.22 -17.8)"
    class="fair"
  />
  <path
    d="M45.7,83.8A4.9,4.9,0,0,1,50.3,80h65.2a3.4,3.4,0,0,0,3.1-2.6l2.5-12.8a2,2,0,0,0-1.4-2.5,1.27,1.27,0,0,0-.6-.1H50c-9.9,0-19.5,8-21.4,17.9l-.8,3.8-2,10.2L25,97.7l-2.8,14.1-.8,3.8L20.1,122l-.8,3.8-.3,1.5-1.2,6.1-1.5,7.7a2,2,0,0,0,1.4,2.5,1.7,1.7,0,0,0,.7.1H31.2a3.4,3.4,0,0,0,3.1-2.6l1.5-7.7,1.5-7.6.8-3.8,1.3-6.4h69a3.4,3.4,0,0,0,3.1-2.6l2.5-12.8a2,2,0,0,0-1.4-2.5,1.7,1.7,0,0,0-.7-.1H43l.8-3.8Z"
    transform="translate(-16.22 -17.8)"
  />
  <path
    d="M136.1,143.9h53.7c9.9,0,19.5-8,21.4-17.9l.8-3.8,2-10.2.8-3.8,2.8-14,.8-3.8,1.3-6.4.8-3.8.3-1.5,1.2-6.1,1.5-7.7a2,2,0,0,0-1.4-2.5,1.27,1.27,0,0,0-.6-.1H208.7a3.4,3.4,0,0,0-3.1,2.6l-1.5,7.7-1.5,7.6-.8,3.8-1.3,6.4-.8,3.8-2.8,14-.8,3.8-2,10.2a4.9,4.9,0,0,1-4.6,3.8h-46a3,3,0,0,1-3.2-2.9,2.77,2.77,0,0,1,.1-.9l2-10.2.8-3.8,2.8-14,.8-3.8,1.3-6.4.8-3.8.3-1.5,1.2-6.1,1.5-7.7a2,2,0,0,0-1.4-2.5,1.27,1.27,0,0,0-.6-.1H137.1a3.4,3.4,0,0,0-3.1,2.6l-1.5,7.7L131,80.3l-.8,3.8-1.3,6.4-.8,3.8-2.8,14-.8,3.8-2,10.2-.8,3.8C119.8,135.9,126.2,143.9,136.1,143.9Z"
    transform="translate(-16.22 -17.8)"
  />
  <path
    d="M512.5,62.1H458.8c-9.9,0-19.5,8-21.4,17.9l-.8,3.8-2,10.2-.8,3.8L431,111.9l-.8,3.8-1.3,6.4-.8,3.8-.3,1.5-1.2,6.1-1.8,8.9a.89.89,0,0,0,.7,1.2h15.6a1.6,1.6,0,0,0,1.5-1.3l1.8-8.9,1.5-7.7.8-3.8,1.3-6.4h53.7l-1.3,6.4-.8,3.8-1.5,7.7-1.8,8.9a.89.89,0,0,0,.7,1.2h15.6a1.6,1.6,0,0,0,1.5-1.3l1.8-8.9,1.2-6.1.3-1.5.8-3.8,1.3-6.4.8-3.8,2.8-14.1L524,94l2-10.2.8-3.8C528.8,70.1,522.4,62.1,512.5,62.1ZM451.8,97.9l2.8-14a4.9,4.9,0,0,1,4.6-3.8h46a3.1,3.1,0,0,1,3.2,2.9,2.77,2.77,0,0,1-.1.9l-2.8,14Z"
    transform="translate(-16.22 -17.8)"
    class="fair"
  />
  <path
    d="M529.5,143.9h12.8a3.4,3.4,0,0,0,3.1-2.6l15.2-76.7a2,2,0,0,0-1.4-2.5,1.27,1.27,0,0,0-.6-.1H545.8a3.4,3.4,0,0,0-3.1,2.6l-12.4,62.9-2.7,13.8a2,2,0,0,0,1.4,2.5A.75.75,0,0,0,529.5,143.9Z"
    transform="translate(-16.22 -17.8)"
    class="fair"
  />
  <path
    d="M645.4,62.1h-69a3.4,3.4,0,0,0-3.1,2.6l-3,15.3-.8,3.8-2,10.2-.8,3.8-2.8,14-.8,3.8-1.2,6.4-.8,3.8-.3,1.5-1.2,6.1-1.5,7.7a2,2,0,0,0,1.4,2.5,1.7,1.7,0,0,0,.7.1H573a3.4,3.4,0,0,0,3.1-2.6l1.5-7.7,1.5-7.7.8-3.8,1.3-6.4h30l13.6,27a2.14,2.14,0,0,0,1.9,1.1h18a1.54,1.54,0,0,0,1.5-1.5.9.9,0,0,0-.1-.5l-13-26.1h1.8c9.9,0,19.5-8,21.4-17.9L657,94l2-10.2.8-3.8C661.7,70.1,655.2,62.1,645.4,62.1Zm-5.6,28.1L639,94a4.9,4.9,0,0,1-4.6,3.8H584.6l.8-3.8,2-10.2.8-3.8H638a2.87,2.87,0,0,1,3.1,2.8V83a2.2,2.2,0,0,1-.1.8Z"
    transform="translate(-16.22 -17.8)"
    class="fair"
  />
  <path
    d="M308.1,62.1H254.5c-9.9,0-19.5,8-21.4,17.9l-.8,3.8-2,10.2-.8,3.8-2.8,14.1-.8,3.8-1.3,6.4-.8,3.8-.3,1.5-1.2,6.1-1.5,7.7a2,2,0,0,0,1.4,2.5,1.27,1.27,0,0,0,.6.1h12.8a3.4,3.4,0,0,0,3.1-2.6l1.5-7.7,1.5-7.6.8-3.8,1.3-6.4.8-3.8,2.8-14.1.8-3.8,2-10.2a4.9,4.9,0,0,1,4.6-3.8h46a3,3,0,0,1,3.2,2.9,2.77,2.77,0,0,1-.1.9l-2,10.2-.8,3.8-2.8,14.1-.8,3.8-1.3,6.4-.8,3.8-.3,1.5-1.2,6.1-1.5,7.7a2,2,0,0,0,1.4,2.5,1.7,1.7,0,0,0,.7.1h12.8a3.4,3.4,0,0,0,3.1-2.6l1.5-7.7,1.5-7.7.8-3.8,1.3-6.4.8-3.8,2.8-14.1.8-3.8,2-10.2.8-3.8C324.4,70.1,318,62.1,308.1,62.1Z"
    transform="translate(-16.22 -17.8)"
  />
</svg>
<h1>ETHEREUM PROXY</h1>
<div id="monitor">
  <svg
    version="1.1"
    id="hex"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56 56"
    style="enable-background: new 0 0 56 56"
    xml:space="preserve"
  >
    <path
      class="hexSpinner6"
      d="M0.4,25.9l13-22.6C13.6,3.1,14,3,14.2,3.1c0.1,0.1,0.2,0.1,0.2,0.2l1.8,3.2c0.2,0.3,0.2,0.7,0,1L5.5,26.2
              c-0.2,0.3-0.6,0.6-1,0.6l-3.5,0c-0.4,0-0.5-0.2-0.6-0.5C0.3,26.1,0.4,26,0.4,25.9z"
    />
    <path
      class="hexSpinner5"
      d="M13.5,50.3l-13-22.6c-0.2-0.3-0.1-0.6,0.2-0.8c0.1-0.1,0.2-0.1,0.3-0.1l3.6,0c0.4,0,0.7,0.2,0.9,0.5l10.8,18.7
              c0.2,0.3,0.2,0.8,0,1.1l-1.8,3c-0.2,0.3-0.5,0.4-0.8,0.3C13.6,50.5,13.5,50.4,13.5,50.3z"
    />
    <path
      class="hexSpinner4"
      d="M41.1,51.1H15c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.2,0.1-0.3l1.9-3.1c0.2-0.3,0.5-0.5,0.9-0.5h21.5
              c0.4,0,0.8,0.2,1,0.6l1.7,3.1c0.2,0.4,0.1,0.6-0.2,0.8C41.3,51.1,41.2,51.1,41.1,51.1z"
    />
    <path
      class="hexSpinner3"
      d="M55.7,27.6l-13,22.6c-0.2,0.3-0.5,0.4-0.8,0.2c-0.1-0.1-0.2-0.1-0.2-0.2L39.9,47c-0.2-0.3-0.2-0.7,0-1
              l10.8-18.7c0.2-0.3,0.6-0.6,1-0.6l3.5,0c0.4,0,0.5,0.2,0.6,0.5C55.8,27.4,55.8,27.5,55.7,27.6z"
    />
    <path
      class="hexSpinner2"
      d="M42.6,3.3l13,22.6c0.2,0.3,0.1,0.6-0.2,0.8c-0.1,0.1-0.2,0.1-0.3,0.1l-3.6,0c-0.4,0-0.7-0.2-0.9-0.5L39.9,7.5
              c-0.2-0.3-0.2-0.8,0-1.1l1.8-3C41.9,3,42.1,3,42.4,3.1C42.5,3.1,42.6,3.2,42.6,3.3z"
    />
    <path
      class="hexSpinner1"
      d="M15,2.4h26c0.3,0,0.6,0.3,0.6,0.6c0,0.1,0,0.2-0.1,0.3l-1.9,3.1c-0.2,0.3-0.5,0.5-0.9,0.5H17.2
              c-0.4,0-0.8-0.2-1-0.6l-1.7-3.1c-0.2-0.4-0.1-0.6,0.2-0.8C14.7,2.4,14.9,2.4,15,2.4z"
    />
  </svg>
</div>
<div class="container-fluid full">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" color="accent">
    <mat-tab *ngFor="let tab of dynamicTabs; let tabIndex = index">
      <ng-template mat-tab-label>{{ tab.title }} </ng-template>
      <div>
        <app-tab-content [tab]="tab"></app-tab-content>
      </div>
    </mat-tab>
  </mat-tab-group>
  <app-footer></app-footer>
  <router-outlet></router-outlet>
</div>

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

export function httpRetry(): (
  // tslint:disable-next-line: no-any
  src: Observable<HttpResponse<any>>
) => // tslint:disable-next-line: no-any
Observable<HttpResponse<any>> {
  // tslint:disable-next-line: no-any
  return (src: Observable<HttpResponse<any>>) =>
    src.pipe(
      // tslint:disable-next-line: no-any
      retryWhen<HttpResponse<any>>((errors) =>
        errors.pipe(
          concatMap((error: HttpErrorResponse) => {
            if (error.status === StatusCodes.TOO_MANY_REQUESTS) {
              const retryAfter =
                // tslint:disable-next-line: radix
                parseInt(error.headers.get('Retry-After') as string) * 1e3;

              return of(error.status).pipe(delay(retryAfter));
            }

            return throwError(error);
          })
        )
      )
    );
}

import { Type } from '@angular/core';
import { NetworkDetails } from 'src/models/network-details';

export class Tab {
  public id!: number;
  public title: string;
  public tabData?: NetworkDetails;
  public active!: boolean;
  public component: Type<any>;

  constructor(component: Type<any>, title: string, tabData?: NetworkDetails) {
    this.tabData = tabData;
    this.component = component;
    this.title = title;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Block, NetworkBlock } from 'src/models/block';
import { NetworkDetails } from 'src/models/network-details';
import { BlockHandlerService } from 'src/services/block-handler.service';

@Component({
  selector: 'app-network-block',
  templateUrl: './network-block.component.html',
  styleUrls: ['./network-block.component.scss'],
})
export class NetworkBlockComponent implements OnInit {
  private newBlockSubscription: Subscription | undefined;

  @Input()
  public network!: NetworkDetails;

  public blocks: Block[] = [];



  public limit = 100;

  private elapsedSeconds: number = 0;
  public lastBlockArrived: Date = new Date(0, 0, 0, 0, 0, 0);

  constructor(private _blockHandlerService: BlockHandlerService) {}

  ngOnDestroy(): void {
    this.newBlockSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.newBlockSubscription = this._blockHandlerService.newBlock.subscribe(
      (block: NetworkBlock) => {
          if (block.network.toLowerCase() === this.network.name.toLowerCase()) {
            this.addBlock(block);
            this.resetTimer();
          }
      }
    );
    timer(0, 1000).subscribe(() => {
      this.elapsedSeconds++;
      this.lastBlockArrived = new Date(0, 0, 0, 0, 0, this.elapsedSeconds);
    });
  }

  private resetTimer() {
    this.elapsedSeconds = 0;
    this.lastBlockArrived = new Date(0, 0, 0, 0, 0, this.elapsedSeconds);
  }

  private addBlock(block: NetworkBlock): void {
    this.blocks.unshift(block);

    if (this.blocks.length > this.limit) {
      //remove block higher than limit
      this.blocks.splice(this.limit);
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { Block, NetworkBlock } from 'src/models/block';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class BlockHandlerService {
  public newBlock = new EventEmitter<NetworkBlock>();
  private log: Logger;
  constructor() {
    this.log = new Logger(this.constructor.name, this);
  }

  /**
   * Block event handler
   */
  public async handler(network: string, block: Block): Promise<void> {
    this.log.debug(network, block);

    const networkBlock: NetworkBlock = {
      network: network,
      number: block.number,
      hash: block.hash,
      timestamp: block.timestamp,
      logsBloom: block.logsBloom,
      parentHash: block.parentHash
    };

    this.newBlock.next(networkBlock);
  }
}

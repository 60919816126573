<div>
  <span>Limit blocks:  <input class="limit" [value]="limit" type="number"/></span>
  <span *ngIf="blocks.length === 0">Waiting for first blocks to arrive.....</span>
  <span *ngIf="blocks.length !== 0" class="timer">Last block {{ this.lastBlockArrived | shortDate }}</span>
</div>
<div *ngIf="blocks.length !== 0">
  <table class="blockTable hover first-row">
    <thead>
    <tr>
      <th>Block</th>
      <th>Hash</th>
      <th>Mined</th>
      <th>Parent</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let block of blocks">
      <td class="blockNumber">{{ block.number | bigNumber }}</td>
      <td class="blockHash">{{ block.hash }}</td>
      <td class="timestamp">{{ block.timestamp | longDate }}</td>
      <td class="blockHash">{{ block.parentHash }}</td>
    </tr>
    </tbody>
  </table>
</div>

import { HubConnectionState } from '@microsoft/signalr';
import { Subscription, timer } from 'rxjs';
import { NetworkDetails } from 'src/models/network-details';
import { Logger } from 'src/services/logger.service';
import { HubConnectionBuilderService } from './hub-connection-builder.service';

export class StatusStreamingHubService {
  private hubConnection: signalR.HubConnection;
  private streamSubscription?: signalR.ISubscription<any>;
  private _log: Logger;
  private timerSubscription!: Subscription;

  /**
   * networkDetails and next can't be passed vai constructor
   * due to component that has network details defined as Output,
   * so it is known only in binding phase
   */
  private networkDetails!: NetworkDetails;
  private next!: (value: any) => void;

  constructor(private _hubConnectionBuilder: HubConnectionBuilderService) {
    this.hubConnection = this._hubConnectionBuilder.build();
    this._log = new Logger(this.constructor.name);
  }

  async start(
    networkDetails: NetworkDetails,
    next: (value: any) => void
  ): Promise<void> {
    this.networkDetails = networkDetails;
    this.next = next;

    await this._start();
  }

  async stop(): Promise<void> {
    if (this.streamSubscription) {
      this.streamSubscription?.dispose();
      this.hubConnection.stop();
    }
  }

  private async _start(): Promise<void> {
    await this.hubConnection.start();

    this.streamSubscription = this.hubConnection
      .stream('Status', this.networkDetails.name)
      .subscribe({
        next: this.next,
        complete: () => {
          this._log.info('Stream completed');
        },
        error: (err) => {
          this._log.error(err);
          this.reconnect();
        },
      });
  }

  /**
   * Try to reconnect every 10 seconds and release timer after success
   */
  private async reconnect(): Promise<void> {
    this.timerSubscription = timer(0, 10000).subscribe(async () => {
      if (this.hubConnection.state === HubConnectionState.Disconnected) {
        await this._start();
        this.timerSubscription.unsubscribe();
      }
    });
  }
}

import { HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, InjectionToken } from '@angular/core';
import { throwError } from 'rxjs';
import { AppConfigJson } from './models/app-config';

@Injectable()
export class AppConfigService {
  public static appConfigJson: AppConfigJson | undefined;

  private _httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    // use this so interceptors dont get in the way
    this._httpClient = new HttpClient(handler);
  }

  /**
   * Get the config held in-memory
   */
  public getConfig(): AppConfigJson {
    if (AppConfigService.appConfigJson) {
      return AppConfigService.appConfigJson;
    }

    throw this.error();
  }

  /**
   * Get the server URL from the config
   */
  public get serverUrl(): string {
    if (AppConfigService.appConfigJson) {
      return AppConfigService.appConfigJson.serverUrl;
    }

    throw this.error();
  }

  /**
   * Get the hub URL from the config
   */
  public get hubUrl(): string {
    if (AppConfigService.appConfigJson) {
      return AppConfigService.appConfigJson.serverUrl.replace(
        'https://',
        'wss://'
      );
    }

    throw this.error();
  }

  /**
   * Get the server version from the config
   */
  public get serverVersion(): string {
    if (AppConfigService.appConfigJson) {
      return AppConfigService.appConfigJson.serverVersion;
    }

    throw this.error();
  }

  /**
   * Get the client version from the config
   */
  public get clientVersion(): string {
    if (AppConfigService.appConfigJson) {
      return AppConfigService.appConfigJson.clientVersion;
    }

    throw this.error();
  }

  /**
   * Renders error
   */
  private error(): Error {
    return new Error('Config should be loaded on startup only');
  }

  /**
   * Load the config file and store it in-memory
   */
  public load(): Promise<boolean> {
    return new Promise((resolve) => {
      this._httpClient.get<AppConfigJson>('./config/api.json').subscribe({
        next: (config: AppConfigJson) => {
          console.log(
            'Ethereum Token Transfer running on url -',
            config.serverUrl
          );
          console.log(
            'Ethereum Token Transfer running on version -',
            config.serverVersion
          );
          console.log(
            'Ethereum Token Transfer monitor running on version -',
            config.clientVersion
          );
          AppConfigService.appConfigJson = config as AppConfigJson;
          resolve(true);
        },
        error: (error) => {
          console.error(error);
          return throwError('Could not read `api.json` file');
        },
      });
    });
  }
}

/**
 * Export the app config factory
 * @param config The config
 */
export function AppConfigFactory(
  config: AppConfigService
): () => Promise<boolean> {
  return () => config.load();
}

/**
 * Init function which can be used in module
 */
export function init(): {
  provide: InjectionToken<(() => void)[]>;
  useFactory: (config: AppConfigService) => () => Promise<boolean>;
  deps: typeof AppConfigService[];
  multi: boolean;
} {
  return {
    provide: APP_INITIALIZER,
    useFactory: AppConfigFactory,
    deps: [AppConfigService],
    multi: true,
  };
}

/**
 * Wrap it into a app config module for a descriptive name
 */
const AppConfigModule = {
  init,
};

export { AppConfigModule };

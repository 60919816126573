import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Errors } from 'src/common/errors';
import { AppConfigService } from 'src/config/app-config.service';
import { Logger, LogLevel } from 'src/services/logger.service';
import { IHubConnectionOptions } from './hub-connection-contracts';

@Injectable({
  providedIn: 'root',
})
export class HubConnectionBuilderService {
  private _log: Logger;

  constructor(private _appConfigService: AppConfigService) {
    this._log = new Logger(this.constructor.name);
  }

  /**
   * Create new instance of HubConnection
   * @param connectionOptions connection options details
   */
  public build(): signalR.HubConnection {
    const connectionOptions = this.getHubConnectionOptions();
    this._log.debug('Creating the hub connection options', connectionOptions);

    try {
      return new signalR.HubConnectionBuilder()
        .withUrl(connectionOptions.endpoint, connectionOptions.options)
        .build();
    } catch (error) {
      throw Errors.runtimeErrorWithLogger(
        this._log,
        'Creation of hub connection failed',
        error
      );
    }
  }

  /**
   * Create new instance of HubConnection
   * @param connectionOptions connection options details
   */
  private buildHubConnection(): signalR.HubConnection {
    const connectionOptions = this.getHubConnectionOptions();
    console.log(`Connecting to ${connectionOptions.endpoint}...`);
    return new signalR.HubConnectionBuilder()
      .withUrl(connectionOptions.endpoint, connectionOptions.options)
      .build();
  }

  /**
   * Get hub connection options
   */
  private getHubConnectionOptions(): IHubConnectionOptions {
    return {
      options: {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        logger: this.getLogLevelForSignalR(),
      },
      endpoint: this.buildSignalREndpoint(),
    };
  }

  /**
   * Get logging level for signal that corespond with logging level in application
   */
  private getLogLevelForSignalR(): signalR.LogLevel {
    switch (Logger.level) {
      case LogLevel.Debug:
        return signalR.LogLevel.Debug;
      case LogLevel.Error:
        return signalR.LogLevel.Error;
      case LogLevel.Info:
        return signalR.LogLevel.Information;
      case LogLevel.Warning:
        return signalR.LogLevel.Warning;
      case LogLevel.Off:
        return signalR.LogLevel.None;
      default:
        return signalR.LogLevel.None;
    }
  }

  /**
   * Build signalR endpoint
   * @param endpoint The endpoint
   */
  private buildSignalREndpoint(): string {
    return `${this._appConfigService.hubUrl}streamingHub/`;
  }
}

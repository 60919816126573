import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class ApiBase {
  constructor(private _route: string) {}

  /**
   * Build endpoint
   * @param endpoint The endpoint
   */
  protected buildEndpoint(endpoint: string): string {
    return `${this._route}${endpoint}`;
  }

  /**
   * Return the base endpoint
   */
  protected baseEndpoint(): string {
    return this._route;
  }

  /**
   * Get error message and will throw the correct error
   * @param httpResponse The http response
   */
  protected getErrorMessage$(
    httpResponse: HttpErrorResponse
  ): Observable<never> {
    if (httpResponse.url) {
      const endpoint = httpResponse.url!.substring(
        httpResponse.url!.indexOf(this._route)
      );

      const translationKey = this.buildTranslationKey(
        endpoint,
        httpResponse.status
      );

      return throwError(this.getErrorMessage(httpResponse));
    }

    return throwError(httpResponse.message);
  }

  /**
   * Build translation keys
   * @param endpoint The endpoint
   * @param httpStatus The http status
   */
  private buildTranslationKey(endpoint: string, httpStatus: number): string {
    // then its a base endpoint
    if (this._route === endpoint) {
      return `api.${this._route}.${httpStatus}`;
    } else {
      // need to handle edge cases here of parameters in URL
      if (endpoint.includes('events')) {
        return `api.${this._route}.events.${httpStatus}`;
      }

      if (endpoint.includes('pending') && endpoint !== 'pending') {
        return `api.${this._route}.pending.${httpStatus}`;
      }

      if (endpoint.includes('cancelled')) {
        return `api.${this._route}.cancelled.${httpStatus}`;
      }

      if (endpoint.includes('complete')) {
        return `api.${this._route}.complete.${httpStatus}`;
      }

      return `api.${this._route}.${endpoint.replace(
        this._route + '/',
        ''
      )}.${httpStatus}`;
    }
  }

  /**
   * Get generic error message
   * @param httpResponse The http response
   */
  private getErrorMessage(httpResponse: HttpErrorResponse): string | undefined {
    switch (httpResponse.status) {
      case StatusCodes.BAD_REQUEST:
        if (
          httpResponse &&
          httpResponse.error &&
          httpResponse.error.errors &&
          Array.isArray(httpResponse.error.errors) &&
          !environment.production
        ) {
          let errorMessage = '';
          for (let i = 0; i < httpResponse.error.errors.length; i++) {
            errorMessage += `${httpResponse.error.errors[i].field} - ${httpResponse.error.errors[i].message}\n`;
          }

          return errorMessage;
        }

        return 'Sorry, something went wrong.';
      case StatusCodes.INTERNAL_SERVER_ERROR:
        return 'Some services seem to not be working at the moment, please try again in a few minutes.';
      case StatusCodes.UNAUTHORIZED:
        return 'You are not signed in, please sign in.';
      default:
        return `Oops something went wrong, please try again or contact support. (${httpResponse.status})`;
    }
  }
}
